@import '../../styles/variables';

.container {
  font-family: $font-default;
  margin: 0 0 1em;
  position: relative;

  [class*='__control'],
  [class*='__value-container'] {
    height: 4rem;
    line-height: 4rem;
  }

  [class*='__control'] {
    border-radius: 2em;
  }

  [class*='__value-container'] {
    padding: 0 0 0 2em;
    display: block;
    position: relative;
  }

  [class*='__placeholder'] {
    margin-left: 0;
    margin-right: 0;
    position: static;
    transform: none;
    line-height: 4em;
  }

  [class*='__option'] {
    line-height: 2em;
  }

  [class*='__indicators'] {
    margin-right: 1em;
  }

  [class*='__indicator-separator'] {
    background-color: rgba(0, 0, 0, 0);
  }

  [class*='__value-container'] *:last-child {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}
