@import '../../styles/variables';

.main {
  background-color: #ffff01;
  width: 100%;
  z-index: 100;
  padding: 21px 0;
  font-family: $font-title;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.04em;
  font-weight: 700;
  text-transform: uppercase;

  .content {
    text-align: center;
    padding: 3px;
    flex: 1;
  }

  .dismissButton {
    position: absolute;
    height: inherit;
    top: 32px;
    right: 30px;
    line-height: 12px;
    cursor: pointer;

    .dismissIcon {
      width: 12px;
      height: 12px;
      display: inline-block;
      background: url('../../assets/images/close-button.svg');
    }
  }
}
