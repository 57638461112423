@import '../../styles/mixins';
@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  max-width: inherit;
  max-height: inherit;
  overflow-y: scroll;
}

.headerSection {
  padding: 3em 2.5em;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: $font-title;
    font-size: 3rem;
    line-height: 1em;
    margin: 0;
    max-width: 9em;
    font-weight: 700;
    margin-bottom: 0.5em;
    letter-spacing: -0.02em;
  }

  @media (max-width: $sm-max-width) {
    padding: 2em 1em;

    h1 {
      font-size: 2rem;
    }
  }
}

.subscriptionHeader h2 {
  font-family: $font-default;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin: 0;

  @media (max-width: $sm-max-width) {
    font-size: 1.5rem;
  }
}

.selectedSubscriptionHeader {
  text-decoration: line-through;
  color: $type-dark-secondary;
  margin-bottom: 0.5rem;
}

.subscriptionToUpsellHeader {
  color: $green-300;
}

.upgradeValueProp {
  font-family: $font-default;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5em;
}

.heroImageSection img {
  width: 100%;
}

.ctaSection {
  padding: 3em 2.5em 2.5em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5em;

  @media (max-width: $sm-max-width) {
    padding: 2em 1em;
  }
}

.ctaButtons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1em;
}

.ctaPrimary {
  background-color: $yellow;
  color: $type-light-primary;
}

.ctaFooter {
  p {
    font-family: $font-default;
    color: $type-dark-secondary;
    font-size: 0.75rem;
    line-height: 1.5em;
    margin: 0;
  }

  @media (max-width: $sm-max-width) {
    padding: 0 2em;
  }
}
