@import '../../styles/variables';

.button {
  font-family: $font-default;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  height: 4em;
  padding: 1.5em 2em;
  border-radius: 2em;
  box-sizing: border-box;
  box-shadow: none;
  color: #ffffff;
  background-color: #000000;
  border: none;
  outline: none;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .loading {
    height: inherit;
    img {
      height: inherit;
      width: auto;
    }
  }
}

.secondary {
  composes: button;
  color: #000000;
  background-color: transparent;
  border: 1px solid #bebebe;
}

.tertiary {
  composes: button;
  background: none;
  border: none;
  color: $type-dark-tertiary;
}

.disabled {
  composes: button;
  background-color: #bebebe;
  color: #ffffff;
  cursor: not-allowed;
}
