@import '../../styles/variables';

.cartSummary {
  font-family: $font-default;
  display: flex;
  flex-direction: column;

  // Make room for the fixed action sheet on mobile
  @media (max-width: $sm-max-width) {
    padding-bottom: 10em;
  }
}

.cartPromoCode {
  composes: smFullWidth from '../../styles/container.module.scss';
}

.cartValuePropsContainer {
  composes: smFullWidth from '../../styles/container.module.scss';
}

.cartValuePropsCelledSection {
  composes: celledSection from '../../styles/celledSection.module.scss';
  display: flex;
  flex-direction: column;
  gap: 1.125em;
  padding: 1.5em;

  @media (max-width: $sm-max-width) {
    border-radius: 0;
  }

  @media (min-width: $md-min-width) and (max-width: $md-max-width), (min-width: $xl-min-width) {
    flex-direction: row;
    align-items: center;
    gap: 3.125em;
    padding: 2em 1.5em;
  }
}

.cartValueProp {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;

  .cartValuePropText {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4em;
  }

  .cartValuePropIcon {
    width: 2.5em;
    height: 2.5em;
    background-color: #ffffff;
    border-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.priceData {
  font-weight: 700;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.4em;
  margin-bottom: 1em;

  @media (max-width: $sm-max-width) {
    justify-content: center;
    gap: 0.5em;
    padding: 0.7em;
    margin-bottom: 0;
  }
}

.summaryActions {
  display: flex;
  margin-bottom: 1em;
  gap: 1em;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  @media (min-width: $md-min-width) and (max-width: $md-max-width), (min-width: $xl-min-width) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    & > * {
      flex-grow: 1;
      flex-basis: 33%;
    }
  }
}

.summaryError {
  font-weight: 600;
  padding: 0 2em;
}

.desktopCartActions {
  composes: container from '../../styles/container.module.scss';
  display: none;

  @media (min-width: $md-min-width) {
    display: flex;
  }
}

.mobileActionSheet {
  composes: container from '../../styles/container.module.scss';
  display: flex;
  padding-bottom: 1em;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

  @media (min-width: $md-min-width) {
    display: none;
  }
}

.footer {
  margin-top: 2em;
  margin-bottom: 3em;
}

.loginButton {
  text-align: center;
  margin-bottom: 1em;
}
