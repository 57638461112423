@import '../../../../../styles/variables';

.productInfo {
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: center;
  gap: 10px;
}

.faqContainer {
  padding-bottom: 11em;
}

.newLabel {
  font-family: $font-title;
  font-size: 1.2em;
  background-color: $collar-yellow;
  border-radius: 100px;
  padding: 0.5em 1em;
  font-weight: 700;
  letter-spacing: 1.92px;
}

.supplementsText {
  margin: inherit; // Don't apply default h1 margin
  font-size: 48px;
  font-weight: 700;
  font-family: $font-title;
  @media (max-width: $sm-min-width) {
    font-size: 40px;
  }
}

.pitch {
  margin-top: 0;
  font-size: 18px;
  text-align: center;
}

.terms {
  margin: 0.5em 0.2em 2em 0.2em; // top right bottom left margins
  display: flex;
  flex-direction: column;
  gap: 1em;
  color: $type-light-secondary;
}

.term {
  font-size: 14px;
  color: $type-light-secondary;
  a {
    color: $type-light-secondary;
  }
  svg {
    width: 15px;
    height: 15px;
    margin-right: 1em;
  }

  @media (max-width: $md-min-width) {
    font-size: 13px;
  }
}

.purchaseDisclaimer {
  margin-top: 3em;
  text-align: center;
}

.getItButtonContainer {
  margin-top: 4em;
  padding-bottom: 1em;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: white;
  z-index: 3; // Buttons should sit in front of all page content.
  border-top: 1px solid $gray-100;
  .getIt {
    color: $type-light-secondary;
    font-weight: 600;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    margin: auto;
    padding: 10px 0;
    @media (max-width: $md-min-width) {
      width: 100%;
    }
  }
  .button {
    width: 100%;
    font-weight: 400;
    margin: 0 10px;
  }
  .selectedDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 5px;
    padding-top: 10px;
    margin: auto;
    width: 25%;

    @media (min-width: $md-min-width) and (max-width: $lg-max-width) {
      width: 40%;
    }
    @media (max-width: $md-min-width) {
      width: 70%;
    }
    @media (max-width: $sm-min-width) {
      width: 90%;
    }
    span {
      font-size: 16px;
      font-weight: 700;
      @media (max-width: $sm-min-width) {
        font-size: 13px;
      }
    }
  }
  .term {
    text-align: center;
    font-size: 14px;
    @media (max-width: $sm-min-width) {
      font-size: 12px;
    }
  }
  .error {
    text-align: center;
  }
}

@media (max-width: $lg-min-width) {
  // Since the width of the elements on the Supplements page only spans about half the width
  // of the page, the value props are getting smushed, so we'll organize them in one
  // column as opposed to two narrow ones.
  .valueProps > div {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }
}

@media (max-width: $md-min-width) {
  .productInfo {
    width: 95%;
  }
}

.imageGalleryContainer {
  composes: mdFullWidth from '../../../../../styles/container.module.scss';
  max-width: 713px;
  margin-bottom: 1em;
}

.desktopImageGallery {
  display: none;

  @media (min-width: $lg-min-width) {
    display: block;
  }
}

.mobileImageGallery {
  display: block;

  @media (min-width: $lg-min-width) {
    display: none;
  }
}
