@import '../../../../../styles/variables';

.imageGalleryContainer {
  composes: stickyLeftColumn from '../../../../../styles/splitContainer.module.scss';
  composes: mdFullWidth from '../../../../../styles/container.module.scss';
}

.desktopImageGallery {
  display: none;

  @media (min-width: $lg-min-width) {
    display: block;
  }
}

:global(.ecom-flow-revamp) .desktopImageGallery {
  display: block;
  padding: 0;
  top: 0px;
}

div:has(:global(.promotionBanner)) :global(.ecom-flow-revamp) .desktopImageGallery {
  @media (min-width: 1025px) {
    top: 92px;
    height: calc(100vh - 92px);
  }
}

.mobileImageGallery {
  display: block;
  width: calc(100% + $mobile-padding * 2);
  margin-top: -1.5em;
  margin-bottom: 0.75em;
  margin-left: -$mobile-padding;

  @media (min-width: $lg-min-width) {
    display: none;
  }
}
