@import '../../../../styles/variables';

.supplementPillContainer {
  padding: 24px;
  border-radius: 24px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  &.paused {
    color: $type-light-secondary;
  }

  &.informational {
    background-color: $gray-200;

    .divider {
      border: 0.5px solid $gray-100;
      margin: 0.7em 0;
    }
  }

  &.selectable,
  &.pause {
    border: 2px solid $gray-400;
  }

  &.selected {
    border: 2px solid black;
  }

  .row {
    display: flex;
    flex-direction: row;
    h2 {
      font-size: 22px;
      font-weight: 600;
      flex-grow: 10;
      margin-top: 0.5em;
    }
    button {
      width: 1rem;
      font-weight: 400;
      height: 50%;
      margin-left: 10px;
      background-color: $gray-300;
      border: none;
      padding: 20px 40px;
      margin: auto;
      font-size: 12px;
    }
  }

  .shipping {
    font-weight: 600;
    font-size: 14px;
  }

  .recommended,
  .nextShipDate {
    font-weight: 300;
    font-size: 11px;
    padding-top: 0.5em;
  }

  .billedAs {
    color: $type-light-secondary;
    font-weight: 600;
    font-size: 11px;
    &.paused {
      color: black;
    }
  }

  .nextShipment {
    font-weight: 300;
  }
}

.purchasableOption {
  height: 11em;
  border: 2px solid $gray-100;
  display: flex;
  font-family: $font-default;
  border-radius: 1.5em;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 1.2em;

  .shipmentInfo {
    width: 150%;
  }
  &.selected {
    border: 2px solid black;
  }

  svg.hideDown {
    display: none;
  }
}
