@import '../../../../styles/variables';

.accessoriesModuleItemContainer {
  margin-top: 24px;
  height: auto;
  font-family: $font-default;
  order: 2;

  & a {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
  }

  .accessoriesModuleItem {
    transition: 0.15s ease-in-out transform, 0.25s ease-in-out background-color;

    @media (min-width: $sm-max-width) {
      &:hover {
        transform: scale(1.02);
        .accessoriesModuleItemImage {
          background: $yellow;
        }
      }
    }
  }
  .accessoriesModuleItemImage {
    background-color: $light-gray;
    border-radius: $radius;
    aspect-ratio: 0.975 / 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    picture > img {
      margin: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      mix-blend-mode: multiply;
    }

    @media (max-width: $sm-max-width) {
      border-radius: 0;

      picture,
      picture > img {
        width: 100%;
      }
    }
  }

  .accessoriesModuleItemDescription {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.01em;
    color: #000000;

    @media (max-width: $sm-max-width) {
      margin-left: 24px;
      margin-right: 24px;
      margin-top: 16px;
      margin-bottom: 40px;
    }

    .accessoriesModuleItemDescriptionPrice {
      font-weight: 400;
    }
  }
}

.showItemAsFirstInGroup {
  order: 1;
}
