@import '../../../../../styles/variables';

.productInfo {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: center;
  gap: 10px;
}

@media (max-width: $md-min-width) {
  .productInfo {
    width: 95%;
  }
}
