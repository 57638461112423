@import '../../styles/variables';

@keyframes slide-up {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
 * Need to use !important to override inline styles that reactjs-popup adds.
 * Using global styles here because their className prop appends to the CSS module hashed class names.
 */
:global(.s3-popup-content) {
  background-color: #ffffff;
  padding: 0 !important;
  border-radius: 1.5em;
  box-sizing: border-box;
  width: auto !important;
  max-width: 95%;
  max-height: 95vh;
  z-index: 105;

  @media (min-width: $md-min-width) {
    max-width: $sm-max-width;
  }

  @media (max-width: $sm-max-width) {
    border-radius: 1.5em 1.5em 0 0;
    width: 100% !important;
    max-width: 100%;
    position: absolute !important;
    margin: 0 !important;
    bottom: 0 !important;
    animation: slide-up 0.15s ease-in-out 0s 1 forwards;
  }
}

:global(.s3-popup-overlay) {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7px);
  z-index: 104;
  animation: fade-in 0.2s linear 0s 1 forwards;
}

.container {
  font-family: $font-default;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  max-width: inherit;
  max-height: inherit;
  display: flex;
  flex-direction: column;
}

.body {
  line-height: 1.4em;
  padding: 4em 3.5em;
  box-sizing: border-box;
  overflow-y: scroll;
  flex-grow: 1;

  p {
    text-align: left;
  }

  h1,
  h2,
  h3 {
    font-family: $font-title;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.5em;
    line-height: 1em;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
  }
}

.actions {
  margin: 0;
  padding: 1em 3.5em 3.5em;

  .button {
    width: 90%;
    margin: auto;
    font-weight: 400;
    margin-bottom: 1em;
  }
}

.closeIcon {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  cursor: pointer;
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: $sm-max-width) {
  .body {
    padding: 4em 1.5em;

    h1 {
      font-size: 3rem;
    }
  }

  .actions {
    padding: 1em 1.5em 1.5em;
  }
}
