@import './styles/variables';

.container {
  font-family: $font-default;
  font-weight: 400;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
