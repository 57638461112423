@import '../../styles/variables';

.fullWidthWrapper {
  background-color: transparent;
  overflow: scroll;
  z-index: 1;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 3em;
}

.container {
  composes: container from '../../styles/container.module.scss';

  .mainHeader {
    font-family: $font-default;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .sectionHeaderContainer {
    margin-bottom: 20px;
  }

  .sectionHeader {
    font-family: $font-default;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .sectionSubheader {
    font-family: $font-default;
    font-size: 12px;
    font-weight: 400;
    color: #737373;
    margin: 0;
  }

  .currentPlanContainer {
    display: flex;
    background-color: #f5f5f2;
    border-radius: 1.5em;
    height: 86px;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: 21px;
    margin-bottom: 28px;
  }

  .currentPlanDescription {
    padding-left: 16px;
  }

  .currentPlanUnit {
    font-family: $font-title;
    font-size: 14px;
    font-weight: 700;
  }

  .currentPlanRate {
    font-family: $font-default;
    font-size: 24px;
    font-weight: 600;
  }

  .petAvatar {
    width: 50px;
    height: 50px;
    border-radius: 12px;
  }

  .button {
    margin-top: 32px;
  }
}
