@import '../../styles/variables';

$collision-width: 410px;

.container {
  font-family: $font-default;
  display: flex;
  align-items: stretch;
  align-content: space-between;
  justify-content: center;
  width: 100%;
}

:global(.ecom-flow-revamp) .container {
  height: 100%;
}

:global(.ecom-flow-revamp) .description {
  color: #737373;
}

.savingsPill,
.valueLabel {
  background-color: $yellow;
  font-size: 0.7rem;
  padding: 0.5em 1.25em;
  font-weight: 700;
  border-radius: 1em;
  white-space: nowrap;
}

:global(.ecom-flow-revamp) .right {
  height: 100%;
}

:global(.ecom-flow-revamp) .right .savingsPill {
  background-color: white;
  border: 1px solid #e5e5e4;
  border-radius: 19px;
  padding: 2px 8px 2px 8px;

  font-family: $font-default;
  font-size: 12px;
  line-height: 140%;
  font-weight: 700;
}

:global(.ecom-flow-revamp) .right .couponExtension {
  background-color: $yellow;
  border: none;
  border-radius: 6px;
  padding: 6px 8px 6px 8px;

  display: flex;
  flex-direction: column;
  margin: 0px;
}

:global(.ecom-flow-revamp) .right .title {
  font-family: $font-default;
  font-size: 10px;
  line-height: 120%;
  font-weight: 600;
}

:global(.ecom-flow-revamp) .right .description {
  font-family: $font-default;
  font-size: 14px;
  line-height: 140%;
  font-weight: 600;
  color: black;
}

.subscriptionExtensionPromo .savingsPill {
  background-color: #ffffff;
  border: 1px solid #bebebe;
  padding: 0.25em 0.75em;
}

.couponExtension {
  margin-left: auto;
  margin-top: 5px;
  border: none;
  border-radius: 0px;
  margin-right: calc(-3em + 2px);

  @media (max-width: $md-max-width) {
    margin-right: calc(-2em - 2px);
  }

  background-color: #fffa01;

  .title {
    font-family: $font-title;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    text-align: right;
    display: inline-block;
    width: 100%;
    margin: 0;
  }

  .description {
    font-family: $font-title;
    font-weight: 700;
    font-size: 14px;
    color: #000000;
    text-align: right;
    display: inline-block;
    width: 100%;
    margin: 0;
  }
}

.valueLabel {
  color: $type-dark-primary;
  font-family: $font-title;
}

.mostPopular {
  background-color: $blue-100;
}

.bestValue {
  background-color: $green-100;
}

.canceled {
  background-color: $type-light-primary;
}

.exclusiveOffer {
  color: $type-light-primary;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.supportPlusWrapper {
  font-size: 0.7rem;
  font-weight: 500;
  margin-top: auto;

  .supportLabel {
    float: right;
    color: #0065f1;
  }

  .supportDescription {
    color: #737373;
  }
}

.billingCadence {
  font-family: $font-title;
  font-weight: 600;
  margin-right: 0.5em;
  font-size: 0.875rem;
  white-space: nowrap;

  @media (min-width: $md-min-width) and (max-width: $md-max-width), (min-width: $xl-min-width) {
    font-size: 1rem;
  }
}

:global(.ecom-flow-revamp) .billingCadence {
  font-family: $font-default;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
}

.price {
  font-size: 1.5rem;
  font-weight: 600;

  @media (max-width: $xs-max-width) {
    font-size: 1rem;
  }
}

:global(.ecom-flow-revamp) .price {
  font-family: $font-default;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
}

:global(.ecom-flow-revamp) .priceBold {
  font-family: $font-default;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
}

.description {
  font-size: 0.8rem;
  margin-top: 0.25em;
  font-weight: 500;
  color: $type-dark-tertiary;
  width: 100%;
}

:global(.ecom-flow-revamp) .description {
  margin: 0;
  font-family: $font-default;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  white-space: nowrap;
}

.column {
  width: 100%;
}

:global(.ecom-flow-revamp) .column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  display: flex;
}

.title {
  align-items: center;
  margin-bottom: 0.5rem;
  @media (max-width: $collision-width) {
    width: 100%;
  }
}

.accommodateSupportPlus {
  @media (max-width: $collision-width) {
    width: 80%;
  }
}
