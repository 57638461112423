@import '../../../styles/variables';
@import '../../../styles/mixins';

.fullWidthWrapper {
  @include webview-full-height();

  background-color: transparent;
  z-index: 1;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  .link {
    text-decoration: none;
    color: inherit;
  }

  .buttonContainer {
    background-color: $bg-light-primary;
    position: sticky; // Buttons should be pinned to bottom of screen.
    bottom: 0;
    padding: 1em 0;
    width: 100%;
    z-index: 1; // Buttons should sit in front of all page content.
    box-shadow: 10px 10px 10px 10px $gray-100;
    .button {
      width: 90%;
      margin: auto;
      font-weight: 400;
    }
    .buttonSpacer {
      height: 4em;
    }
  }

  .collarAvatar {
    width: 101%;
  }
  .makersCollarAvatar {
    margin-bottom: 4em; // Push element up to minimize space between it and the element above it.
  }

  .renewCopy {
    display: flex;
    flex-direction: row;
    margin: 1em;
    gap: 1em;
  }
  .refreshIcon {
    margin: auto;
  }

  .unauthorized {
    margin-top: 40%;
    padding: 0 10px;
    text-align: center;
  }
}

.subscriptionContainer,
.whatsNew {
  composes: container from '../../../styles/container.module.scss';
  align-items: left;
  z-index: 1;
  flex: 1; // Expand to fill remeining vertical space.
  margin-top: 5em; // Prevent content from being hidden behind app bar.
  margin-bottom: 1em; // Prevent content from being hidden behind button padding.

  .header {
    display: flex;
    flex-direction: column;
  }

  h1 {
    text-transform: unset;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: -0.02em;
    color: $type-light-primary;
  }

  .reasonHeader {
    text-transform: unset;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: -0.02em;
    color: $type-light-primary;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .largeHeader {
    h1 {
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
    }
  }

  h3 {
    color: $type-light-secondary;
    font-size: 1em;
    font-weight: 500;
  }

  p {
    margin-top: 10px;
    margin-bottom: 20px;
    color: $type-light-primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }

  .content {
    align-items: center;
    justify-content: center;
  }

  .inlineButtons {
    margin-top: 30px;
    .button {
      margin-top: 1em;
      width: 100%;
    }
    .buttonIcon {
      margin-right: 6px;
    }
  }

  .textArea {
    margin-top: 40px;
    textarea::placeholder {
      color: $type-dark-secondary;
    }
    textarea {
      color: $type-dark-tertiary;
      background: $bg-light-secondary;
      border-radius: 16px;
      border: none;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .featureDescription {
    display: flex;

    .icon {
      width: 40px;
      height: 40px;
      margin-top: 15px;
      margin-right: 10px;
    }

    .finalThought {
      margin-top: 20px;
      text-align: center;
    }
  }
}

.whatsNew {
  margin-top: 1em;
}

.pauseMonthOffer,
.keepMembershipPetImage,
.freeMonthOffer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}

.pauseMonthOfferImages,
.keepMembershipPetImages,
.freeMonthOfferImages {
  position: relative;
}

.pauseMonthOfferAvatar,
.keepMembershipPetImageAvatar,
.freeMonthOfferAvatar {
  img {
    width: 180px;
    border-radius: 30px;
  }
}

.pauseMonthOfferPauseIcon,
.keepMembershipPetImageCheckMarkBackground,
.freeMonthOfferGiftBox {
  position: absolute;
  bottom: 0px;
  right: -10px;
  img {
    width: 60px;
  }
}

.keepMembershipPetImageCheckMark {
  position: absolute;
  bottom: -25px;
  right: -35px;
  img {
    width: 110px;
  }
}

.multidogDiscount {
  font-weight: 400;
  color: $type-light-primary;

  display: flex;
  align-items: stretch;
  align-content: space-between;
  justify-content: center;
  width: 100%;

  border-radius: 24px;
  border: none;
  background-color: $bg-light-secondary;
  margin: 20px 0 0 0;
  padding-top: 20px;
  padding-bottom: 20px;

  .name {
    font-weight: 600;
  }

  .avatar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 20px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
    }
  }

  .prices {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 20px;

    .current {
      text-decoration: line-through;
    }
    .discounted {
      color: $green-200;
    }
  }
  .row {
    margin: 0px;
    margin-bottom: 5px;
    display: flex;
  }
}

.imagesCarouselContainer {
  max-width: $md-max-width;
  width: 100%;
  aspect-ratio: 4 / 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 auto;
  gap: 0;
  border-radius: 1.5em;

  iframe,
  img {
    border-radius: 1.5em;
  }

  .stepCount {
    object-fit: contain;
  }

  .imageCarousel {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .imageCarouselImage {
    width: 100%;
    iframe,
    img {
      width: 100%;
      height: 218px;
    }
    p {
      margin-top: 20px;
    }
    h1 {
      margin-bottom: 20px;
    }
  }

  .testimonial {
    display: flex;

    .lunaOwner {
      width: 40px;
      height: 40px;
      margin-top: 15px;
      margin-right: 10px;
    }
  }

  :global(.flickity-page-dots) {
    display: none;
  }
}

.imagesCarouselContainer {
  height: 570px;

  button {
    visibility: hidden; // Hack to enable custom button functionality
  }
}

.xray {
  padding: 0 10px;
}

.info {
  padding-bottom: 1em;
}

.error {
  margin-top: auto; // Error should be at bottom of screen in place of buttons.
  text-align: center;
  color: red;
}

@media (max-width: $xs-max-width) {
  .imagesCarouselContainer {
    :global(.flickity-page-dots) {
      display: flex;
      flex-direction: row;
      width: auto;
      align-self: center;
      background-color: $bg-light-secondary;
      padding: 0.5em;
      bottom: -0.75em;
      box-sizing: border-box;
      border-radius: 1em;
      gap: 0.5em;
      align-items: center;

      :global(.dot) {
        margin: 0;
        width: 6px;
        height: 6px;
      }

      :global(.dot.is-selected) {
        width: 8px;
        height: 8px;
      }
    }
  }

  .toast {
    background-color: $green-400;
    padding: 10px 20px;
    position: sticky;
    bottom: 20%;
    z-index: 3;
    border-radius: 10px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      padding-right: 10px;
    }
  }
}
