@import '../../../../styles/variables';

.main {
  font-family: $font-default;

  h2 {
    font-size: 4rem;
    font-weight: 700;
    font-family: $font-title;
    margin-top: 0;
    margin-bottom: 0.625em;
  }

  .sizeOptionList {
    display: flex;
    flex-direction: column;
    gap: 3em;
  }
}

.sizingBlock,
.stillUnsure {
  h3 {
    font-size: 20px;
    line-height: 120%;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.1em;
  }

  .sizeDescription {
    margin-bottom: 1em;
  }

  .sizeDescription,
  .dogList {
    font-size: 1rem;
    font-weight: 500;
    color: #737373;
  }

  a {
    color: #000000;
    text-decoration: underline;
  }

  p {
    color: #737373;
  }
}

@media (min-width: $md-min-width) {
  .main {
    .sizeOptionList {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-auto-flow: column;
      gap: 2em;
    }
  }
}
