@import '../../../../../styles/variables';

.container {
  composes: container from '../../../../../styles/container.module.scss';
  max-width: 834px;
  margin: 0 auto; // Center the container since it's outside the product container
}

.heading {
  font-family: $font-title;
  text-align: center;
  font-size: 2rem;
  line-height: 1.1em;

  @media (min-width: $md-min-width) {
    font-size: 3.5em;
  }
}

table,
th,
td {
  padding: 0.5em;
  border: 1px solid;
  border-collapse: collapse;
}
